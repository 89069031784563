import React from 'react';
import { Form, Card, Row, Col } from 'react-bootstrap';
import { getFormValue } from '../../helpers/formdata';

const ConfigMetrics = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Métricas</h2>

      {showSections['metrics'] &&
        (
          <>
          <Row>
            <Col>
            <Form.Group controlId={`configApp-${code}-disable`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'disable')}
                name={`configApp-${code}-disable`}
                type="checkbox"
                label="Desactivar métricas para esta universidad"
              />
            </Form.Group>
            </Col>
          </Row>
          </>
        )}
    </div>
  );
};

export default ConfigMetrics;
