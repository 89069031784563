import React, { useState } from 'react';
import { Form, InputGroup, Row, Col, Button } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { getFormValue } from '../../helpers/formdata';
import { isAdminRole } from '../../helpers/roles';

const AuthorizationBase = ({ code, data, authorization, children }) => {
  const [showUser, setShowUser] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showClientSecret, setShowClientSecret] = useState(false);
  const [showClientId, setShowClientId] = useState(false);
  const [showOAuthFields, setShowOAuthFields] = useState(() =>
    getFormValue(data, code, 'enableOAuthFields') || false);

  return (
    <>
      <Row>
        <Col md={6}>
          <Form.Group controlId={`authorization-${code}-username`}>
            <Form.Label>Usuario</Form.Label>
            <InputGroup>
              <Form.Control
                name={`authorization-${code}-username`}
                defaultValue={getFormValue(authorization, code, 'username')}
                type={showUser ? 'text' : 'password'}
                readOnly={!isAdminRole()}
              />
              {isAdminRole() && (
                <Button variant="eye" onClick={() => setShowUser(!showUser)}>
                  {showUser ? <FaEyeSlash /> : <FaEye />}
                </Button>
              )}
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId={`authorization-${code}-password`}>
            <Form.Label>Contraseña</Form.Label>
            <InputGroup>
              <Form.Control
                name={`authorization-${code}-password`}
                defaultValue={getFormValue(authorization, code, 'password')}
                type={showPassword ? 'text' : 'password'}
                readOnly={!isAdminRole()}
              />
              {isAdminRole() && (
                <Button variant="eye" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </Button>
              )}
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      {code === 'oauth' && (
        <Form.Group controlId={`authorization-${code}-enableOAuthFields`} className="mb-3">
          <Form.Check
            name={`configApp-${code}-enableOAuthFields`}
            type="checkbox"
            label="Usar Grant type Password Credentials"
            // checked={showOAuthFields}
            onChange={() => setShowOAuthFields(!showOAuthFields)}
          />
        </Form.Group>
      )}

      {code === 'oauth' && showOAuthFields && (
        <Row>
          <Col md={6}>
            <Form.Group controlId={`authorization-${code}-clientId`}>
              <Form.Label>Client ID</Form.Label>
              <InputGroup>
                <Form.Control
                  name={`authorization-${code}-clientId`}
                  defaultValue={getFormValue(authorization, code, 'clientId')}
                  type={showClientId ? 'text' : 'password'}
                />
                {isAdminRole() && (
                  <Button
                    variant="eye"
                    onClick={() => setShowClientId(!showClientId)}
                  >
                    {showClientId ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId={`authorization-${code}-clientSecret`}>
              <Form.Label>Client Secret</Form.Label>
              <InputGroup>
                <Form.Control
                  name={`authorization-${code}-clientSecret`}
                  defaultValue={getFormValue(authorization, code, 'clientSecret')}
                  type={showClientSecret ? 'text' : 'password'}
                  readOnly={!isAdminRole()}
                />
                {isAdminRole() && (
                  <Button
                    variant="eye"
                    onClick={() => setShowClientSecret(!showClientSecret)}
                  >
                    {showClientSecret ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      )}

      <Form.Group controlId={`authorization-${code}-urlToken`}>
        <Form.Label>URL Token</Form.Label>
        <Form.Control
          name={`authorization-${code}-urlToken`}
          defaultValue={getFormValue(authorization, code, 'urlToken')}
          type="text"
        />
      </Form.Group>

      <Form.Group controlId={`authorization-${code}-urlModule`}>
        <Form.Label>URL Módulo</Form.Label>
        <Form.Control
          name={`authorization-${code}-urlModule`}
          defaultValue={getFormValue(authorization, code, 'urlModule')}
          type="text"
        />
      </Form.Group>

      <Form.Group controlId={`authorization-${code}-idModule`}>
        <Form.Label>ID Módulo</Form.Label>
        <Form.Control
          name={`authorization-${code}-idModule`}
          defaultValue={getFormValue(authorization, code, 'idModule')}
          type="text"
        />
      </Form.Group>

      <Form.Group controlId={`authorization-${code}-token`} style={{ display: "none" }}>
        <Form.Label>Token</Form.Label>
        <Form.Control
          name={`authorization-${code}-token`}
          defaultValue={getFormValue(authorization, code, 'token')}
          type="text"
          readOnly
        />
      </Form.Group>

      {children}
    </>
  );
};

export default AuthorizationBase;
