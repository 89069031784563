import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import SelectSystem from '../../widgets/selectSystem';
import SelectCache from '../../widgets/selectCache';
import { getFormValue } from '../../../helpers/formdata';
import { normalize } from '../../../helpers/utils';
import { endpoint } from '../../../helpers/utils';
import SelectSharedCache from '../../widgets/selectSharedCache';

const GeneralItem = ({ item, data, enabled }) => {
  const [code, setCode] = useState(null);
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setCode(normalize(item.generalId));
    setUrl(endpoint(item.generalId));
  }, [item]);
  if (!code) {
    return false;
  }
  return (
    <>
      <Row>
        <Form.Check
          name={`general-${code}-enabled`}
          checked={enabled}
          className="visually-hidden"
          onChange={() => false}
          type="checkbox"
        />
        <Col>
          <Form.Group controlId={`general-${code}-url`}>
            <Form.Label>URL {item.name.toLowerCase()}</Form.Label>
            <Form.Control
              name={`general-${code}-url`}
              defaultValue={getFormValue(data, code, 'url')}
              type="text"
            />
            <Form.Text className="text-muted">AWS {url}</Form.Text>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group controlId={`general-${code}-system`}>
            <Form.Label>Sistema fuente</Form.Label>
            <SelectSystem
              name={`general-${code}-system`}
              value={getFormValue(data, code, 'system')}
              defaultValue="default"
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group controlId={`general-${code}-cache`}>
            <Form.Label>Caché</Form.Label>
            <SelectCache
              name={`general-${code}-cache`}
              value={getFormValue(data, code, 'cache')}
            />
          </Form.Group>
        </Col>
        {/*TODO De momento en uxxi no quiern cache en la pestaña general
       <Col md={2}>
        <Form.Group controlId={`academic-${code}-shared`}>
          <Form.Label>Caché compartida</Form.Label>
          <SelectSharedCache
            name={`academic-${code}-shared`}
            value={getFormValue(data, code, 'shared')}
          />
        </Form.Group>
      </Col> */}
      </Row>
    </>
  );
};
export default GeneralItem;
