import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const SelectSystem = ({ name, value = [], defaultValue = 'default' }) => {
  const [data, setData] = useState(value);
  const [loading, setLoading] = useState(true);

  const environment = `${process.env.REACT_APP_CODE}`;

  const optionsConfig = {
    APP: [
      'academic',
      'economic',
      'investigation',
      'humanresource',
      'securetitle_idgriu',
      'spaces',
      'tui_basicauth',
      'tui_soap',
      'blackboard',
      'moodle',
      'cms',
      'ufv',
      'portal',
      'stack',
      'comunica',
      'studium',
    ],
    PS: [
      'academic',
      'economic',
      'investigation',
      'humanresource',
      'securetitle_idgriu',
      'spaces',
      'tui_basicauth',
      'tui_soap',
      'blackboard',
      'moodle',
      'cms',
      'ufv',
      'portal',
      'stack',
      'comunica',
      'studium',
      'oauth'
    ],
    ALUMNI: [
      'academic',
      'tui_basicauth',
      'tui_soap',
      'blackboard',
      'moodle',
      'cms',
      'stack',
      'comunica',
      'spaces'
    ],
  };

  const allOptions = {
    academic: 'Académico',
    economic: 'Económico',
    investigation: 'Investigación',
    humanresource: 'Recursos Humanos',
    securetitle_idgriu: 'Título Seguro',
    spaces: 'Espacios UXXI',
    tui_basicauth: 'TUI BasicAuth',
    tui_soap: 'TUI SOAP',
    blackboard: 'Blackboard',
    moodle: 'Moodle',
    cms: 'CMS',
    ufv: 'UFV',
    portal: 'Portal',
    stack: 'Stack',
    comunica: 'Comunica',
    studium: 'Studium',
    oauth: 'OAuth'
  };

  const filteredOptions = optionsConfig[environment] || Object.keys(allOptions);

  const handleChange = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setData(selectedValues);
  };

  useEffect(() => {
    setLoading(false);
    setData(value.length > 0 ? value : [defaultValue]);
  }, [value, defaultValue]);

  if (loading) {
    return null;
  }

  return (
    <Form.Control
      as="select"
      multiple={false}
      name={name}
      value={data}
      onChange={handleChange}
    >
      <option value="default">-- Sin sistema fuente --</option>
      {filteredOptions.map((option) => (
        <option key={option} value={option}>
          {allOptions[option]}
        </option>
      ))}
    </Form.Control>
  );
};

export default SelectSystem;
