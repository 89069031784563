import React from 'react';
import { Form, Card, Row, Col } from 'react-bootstrap';
import { getFormValue } from '../../helpers/formdata';

const ConfigLogging = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Administración de Logs </h2>

      {showSections['logging'] &&
        (
          <>
          <Row className="mt-3">
            <Col sm={4}>
              <Form.Group controlId={`configApp-${code}-level`}>
                <Form.Label>Seleccionar nivel logging</Form.Label>
                <Form.Select
                  defaultValue={getFormValue(data, code, 'level') || 'CRITICAL'}
                  name={`configApp-${code}-level`}
                >
                  <option value="DEBUG">1-DEBUG</option>
                  <option value="INFO">2-INFO</option>
                  <option value="WARNING">3-WARNING</option>
                  <option value="ERROR">4-ERROR</option>
                  <option value="CRITICAL">5-CRITICAL</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          </>
        )}
    </div>
  );
};

export default ConfigLogging;
